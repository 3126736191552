.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  padding-top: 20px;
  min-height: 100vh;
}

.App-link {
  color: #09d3ac;
}

.App-header {
  background-image: url("home-background.jpg");
  -webkit-background-size: cover; /* For WebKit*/
  -moz-background-size: cover;    /* Mozilla*/
  -o-background-size: cover;      /* Opera*/
  background-size: cover;         /* Generic*/
}